var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.is_loading)?_c('loading'):(_vm.subPermission.reports && !_vm.subPermission.reports.show)?_c('content-not-view'):_c('b-card',{staticClass:"w-100"},[_c('b-row',{staticClass:"d-flex"},[_c('b-col',{staticClass:"col-2 mr-n1 d-flex justify-content-center align-items-center"},[_c('b-avatar',{attrs:{"src":_vm.currentAvatar,"size":"7rem"}})],1),_c('b-col',[_c('b-row',[_c('h4',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.data.user.first_name)+" "+_vm._s(_vm.data.user.middle_name)+" "+_vm._s(_vm.data.user.last_name)+" ")])]),_c('b-row',[_c('span',[_vm._v(" "+_vm._s(_vm.$helpers.dateTimeConvertFunction(_vm.data.created_at))+" - ")]),_c('h4',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.data.reportReason.name)+" ")])]),_c('b-row',{staticClass:"mt-1"},[_c('b-form-textarea',{staticClass:"w-75 text-area",attrs:{"disabled":"","max-rows":"6","no-resize":true,"value":_vm.data.message}})],1)],1)],1),_c('b-row',{staticClass:"d-flex justify-content-center mt-1"},[(_vm.type === 'Post' && _vm.subPermission.posts.show)?_c('b-link',{attrs:{"to":{
          name: 'showPost',
          params: {
            boardId: _vm.data.reported_post.list.board.id,
            listId: _vm.data.reported_post.list.id,
            postId: _vm.data.reported_post.id,
          },
        }}},[_vm._v(" "+_vm._s(_vm.$t("g.view_report/links/view_post"))+" ")]):(_vm.type === 'Comment' && _vm.data.reported_comment)?_c('div',{staticClass:"d-block mt-n1"},[(
            _vm.data.reported_comment &&
            _vm.subPermission.comments &&
            _vm.subPermission.comments.show
          )?_c('comment-component',{staticClass:"row",attrs:{"page_data":_vm.data.reported_comment},on:{"close-report":_vm.closeReport}}):_vm._e(),(
            _vm.data.reported_comment &&
            _vm.data.comment &&
            _vm.subPermission.comments &&
            _vm.subPermission.comments.show
          )?_c('b-link',{staticClass:"link-forPost row mt-1 d-flex justify-content-center",attrs:{"to":{
            name: 'showPost',
            params: {
              boardId: _vm.data.reported_comment.comment_on.list.board.id,
              listId: _vm.data.reported_comment.comment_on.list.id,
              postId: _vm.data.reported_comment.comment_on.id,
            },
          }}},[_vm._v(" "+_vm._s(_vm.$t("g.view_report/links/view_post"))+" ")]):_vm._e()],1):(_vm.type === 'Comment' && !_vm.data.reported_comment)?_c('div',{staticClass:"deleted-alert"},[_vm._v(" "+_vm._s(_vm.$t("g.commentHasBeenDeleted"))+" ")]):(
          _vm.type === 'User' && _vm.subPermission.users && _vm.subPermission.users.index
        )?_c('b-link',{attrs:{"to":{ name: 'UsersList' }}},[_vm._v(" "+_vm._s(_vm.$t("g.view_report/links/view_user"))+" ")]):_vm._e()],1),(_vm.subPermission.reports && _vm.subPermission.reports.update)?_c('b-row',[_c('b-form-textarea',{staticClass:"w-75 text-area-message",attrs:{"placeholder":"Message","disabled":_vm.data.closed_at ? true : false,"rows":"4","no-resize":true},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}})],1):_vm._e(),_c('b-row',{staticClass:"d-flex justify-content-end pr-2 mt-1"},[(
          !_vm.data.closed_at &&
          _vm.subPermission.reports &&
          _vm.subPermission.reports.update
        )?_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.closeReport}},[_vm._v(_vm._s(_vm.comment ? _vm.$t("g.view_report/button/close_with_message") : _vm.$t("g.view_report/button/close")))]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }