<template>
  <div>
    <loading v-if="is_loading" />
    <content-not-view
      v-else-if="subPermission.reports && !subPermission.reports.show"
    />
    <b-card v-else class="w-100">
      <b-row class="d-flex">
        <b-col
          class="col-2 mr-n1 d-flex justify-content-center align-items-center"
        >
          <b-avatar :src="currentAvatar" size="7rem" />
        </b-col>
        <b-col>
          <b-row>
            <h4 class="font-weight-bold">
              {{ data.user.first_name }} {{ data.user.middle_name }}
              {{ data.user.last_name }}
            </h4>
          </b-row>
          <b-row>
            <span>
              {{ $helpers.dateTimeConvertFunction(data.created_at) }} -
            </span>
            <h4 class="font-weight-bold">
              {{ data.reportReason.name }}
            </h4>
          </b-row>
          <b-row class="mt-1">
            <b-form-textarea
              class="w-75 text-area"
              disabled
              max-rows="6"
              :no-resize="true"
              :value="data.message"
            />
          </b-row>
        </b-col>
      </b-row>
      <b-row class="d-flex justify-content-center mt-1">
        <b-link
          v-if="type === 'Post' && subPermission.posts.show"
          :to="{
            name: 'showPost',
            params: {
              boardId: data.reported_post.list.board.id,
              listId: data.reported_post.list.id,
              postId: data.reported_post.id,
            },
          }"
        >
          {{ $t("g.view_report/links/view_post") }}
        </b-link>
        <div
          v-else-if="type === 'Comment' && data.reported_comment"
          class="d-block mt-n1"
        >
          <comment-component
            v-if="
              data.reported_comment &&
              subPermission.comments &&
              subPermission.comments.show
            "
            class="row"
            :page_data="data.reported_comment"
            @close-report="closeReport"
          />
          <b-link
            v-if="
              data.reported_comment &&
              data.comment &&
              subPermission.comments &&
              subPermission.comments.show
            "
            :to="{
              name: 'showPost',
              params: {
                boardId: data.reported_comment.comment_on.list.board.id,
                listId: data.reported_comment.comment_on.list.id,
                postId: data.reported_comment.comment_on.id,
              },
            }"
            class="link-forPost row mt-1 d-flex justify-content-center"
          >
            {{ $t("g.view_report/links/view_post") }}
          </b-link>
        </div>
        <div
          v-else-if="type === 'Comment' && !data.reported_comment"
          class="deleted-alert"
        >
          {{ $t("g.commentHasBeenDeleted") }}
        </div>
        <b-link
          v-else-if="
            type === 'User' && subPermission.users && subPermission.users.index
          "
          :to="{ name: 'UsersList' }"
        >
          {{ $t("g.view_report/links/view_user") }}
        </b-link>
      </b-row>
      <b-row v-if="subPermission.reports && subPermission.reports.update">
        <b-form-textarea
          v-model="comment"
          placeholder="Message"
          :disabled="data.closed_at ? true : false"
          class="w-75 text-area-message"
          rows="4"
          :no-resize="true"
        />
      </b-row>
      <b-row class="d-flex justify-content-end pr-2 mt-1">
        <b-button
          v-if="
            !data.closed_at &&
            subPermission.reports &&
            subPermission.reports.update
          "
          variant="primary"
          @click="closeReport"
          >{{
            comment
              ? $t("g.view_report/button/close_with_message")
              : $t("g.view_report/button/close")
          }}</b-button
        >
      </b-row>
    </b-card>
  </div>
</template>
<script>
import {
  BFormTextarea,
  BRow,
  BCol,
  BCard,
  BAvatar,
  BLink,
  BButton,
} from "bootstrap-vue";
import Loading from "@/components/loading/loading.vue";
import CommentComponent from "@/components/report/PostComment/index.vue";
import contentNotView from "@/components/contentNotView/contentNotView.vue";

export default {
  name: "ViewReport",
  components: {
    BCol,
    BCard,
    Loading,
    BAvatar,
    BRow,
    BLink,
    BFormTextarea,
    CommentComponent,
    BButton,
    contentNotView,
  },
  data() {
    return {
      data: null,
      is_loading: true,
      type: null,
      comment: null,
      currentAvatar: null,
    };
  },
  computed: {
    subPermission() {
      this.$store.dispatch("GET_PERMISSIONS", [
        "reports",
        "comments",
        "posts",
        "users",
      ]);
      return this.$store.state.userData.sub_Permissions;
    },
  },
  beforeMount() {
    if (this.$route.params.id) {
      this.currentId = this.$route.params.id;
      this.getdata();
    } else {
      this.is_loading = false;
    }
  },
  methods: {
    getdata() {
      this.$http
        .get(`admin/reports/${this.currentId}`)
        .then((response) => {
          this.data = response.data.data;
          this.type = this.data.reportable_type.split("\\")[2];
          this.avatar();
        })
        .catch((error) => {
          this.$helpers.makeToast(
            "danger",
            error.message,
            this.$t("g.send.errorTitle")
          );
          this.is_loading = false;
        })
        .finally((this.is_loading = false));
    },
    closeReport() {
      const date = new Date();
      const formData = new FormData();
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000000Z`;

      formData.append("closed_at", formattedDate);
      if (this.comment) {
        formData.append("comment", this.message);
      }
      this.$http
        .post(`admin/reports/${this.data.id}?_method=put`, formData)
        .then((res) => {
          this.$helpers.makeToast(
            "success",
            res.message,
            this.$t("g.send.successBody")
          );
          this.getdata();
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
    avatar() {
      const userData = this.data.user;
      if (userData) {
        if (Array.isArray(userData.media) && userData.media.length > 0) {
          this.currentAvatar = userData?.media[0].path;
        } else {
          const uiAvatar = this.$helpers.uiAvatar(
            `${userData.first_name} ${userData.last_name}`
          );
          this.currentAvatar = uiAvatar;
        }
      }
    },
  },
};
</script>
<style lang="scss">
@import "./index";
</style>
