<template>
  <div class="comment">
    <div class="info">
      <img
        v-if="page_data.user"
        :src="
          [...page_data.user.media].length != 0
            ? page_data.user.media[0].path
            : require('@/assets/images/pages/classRoom/boards/posts/showPost/dummyPerson.png')
        "
        alt=""
      />

      <div class="text">
        <span
          >{{ page_data.user ? page_data.user.first_name : null }}
          {{ page_data.user ? page_data.user.last_name : null }}
          <span
            class="user-type"
            v-if="page_data.user.id === page_data.comment_on.user.id"
            >Creator</span
          ></span
        >
        <span>{{
          $helpers.dateTimeConvertFunction(page_data.created_at)
        }}</span>
      </div>
      <div class="dropdwon" v-if="subPermission.destroy">
        <feather-icon
          icon="Trash2Icon"
          size="16"
          class="text-body align-middle mr-25"
          @click="deleteCommentAlart"
        />
      </div>
      <div class="content">
        {{ page_data.comment }}
      </div>
    </div>
  </div>
</template>
<script>
import { BDropdown, BDropdownItem } from "bootstrap-vue";
import { formatDate } from "@/@core/utils/filter";
export default {
  name: "PostComment",
  props: ["page_data"],
  components: {
    BDropdown,
    BDropdownItem,
  },
  mounted() {
    this.data = this.page_data;
    this.$store.dispatch("GET_PERMISSION", "comments");
    this.subPermission = this.$store.state.userData.sub_Permission;
  },
  data() {
    return {
      data: null,
      subPermission: {},
    };
  },
  methods: {
    deleteCommentAlart() {
      this.$swal({
        title: this.$t("g.areYouSure?"),
        text: this.$t("g.youWontBeAbleToRevertThis"), // You won't be able to revert this!
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("g.yesDeleteIt"), // "Yes, delete it!" ,
        cancelButtonText: this.$t("g.cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteComment();
          this.$swal({
            icon: "success",
            title: this.$t("g.deleted"), //"Deleted!"
            text: this.$t("g.yourFileHasBeenDeleted"), //"Your file has been deleted."
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      });
    },
    deleteComment() {
      this.$http
        .delete(
          `admin/boards/${this.page_data.comment_on.list.board.id}/lists/${this.page_data.comment_on.list.id}/posts/${this.page_data.comment_on.id}/comments/${this.page_data.id}`
        )
        .then((res) => {
          if (res.status === 200) {
            this.$emit("close-report");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./postComponent";
</style>
